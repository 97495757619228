import React from "react"
import "./LabeledTextInput.scss"
import TextareaAutosize from "react-textarea-autosize"
const LabeledTextInput = ({ formElement, onChangeValue }) => {
    return (
        <div className="freeTextContainer" data-testid={`freeText-${formElement.id}`}>
            <label htmlFor="freeTextInput" className="question">
                {formElement.text}
            </label>
            <TextareaAutosize
                id="freeTextInput"
                className="input"
                value={formElement.value ?? ""}
                onChange={(event) => onChangeValue(formElement.id, event.target.value)}
            />
        </div>
    )
}

export default LabeledTextInput
