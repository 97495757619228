import React, { useState, useEffect } from "react"
import "./LabeledSlider.scss"
import _ from "lodash"
import { RadioGroup, Radio } from "react-radio-group"
const LabeledSlider = ({ formElement, onChangeValue }) => {
    const inputRange = _.range(formElement.min, formElement.max + 1, 1)
    const [value, setValue] = useState<number>(formElement.value)

    useEffect(() => {
        value && onChangeValue(formElement.id, value)
    }, [value])

    return (
        <div className="radioContainer" data-testid={`slider-${formElement.id}`}>
            <p className="rangeQuestion">{formElement.text}</p>
            <div className="labelContainer">
                <p className="radioText">not at all</p>
                <RadioGroup className="radioGroup" selectedValue={value} onChange={setValue}>
                    {inputRange.map((val) => (
                        <Radio className="radio" value={val} key={val} />
                    ))}
                </RadioGroup>
                <p className="radioText"> very much </p>
            </div>
        </div>
    )
}

export default LabeledSlider
