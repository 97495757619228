export const fetchWithTimeout = async (url: string, options: RequestInit, timeout: number): Promise<Response> => {
    const controller = new AbortController()
    const timerToAbort = setTimeout(() => {
        controller.abort()
    }, timeout)
    let res = new Response()
    try {
        res = await fetch(url, {
            ...options,
            signal: controller.signal,
        })
        if (!res.ok) {
            throw new Error(res.statusText)
        }
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
    clearTimeout(timerToAbort)
    return res
}
