import { awaitStream } from "../../util/streamUtils"
import React, { useEffect, useState, useRef, MutableRefObject, ReactElement } from "react"
import { StreamPlayerControls, StreamPlayer } from "./StreamPlayer"
import { getSessionInformationWithSecret } from "../../util/freudApiUtil"
import "./IntegrationAudio.scss"
import { useMixpanel } from "../../../plugins/gatsby-plugin-mixpanel"
const streamBase = process.env.GATSBY_JUNG_AUDIO_STREAM_URL || "https://freud-streams-dev.wavepaths.com/streamdata"

const IntegrationAudio = ({
    creatorId,
    sessionId,
    hash,
    onPlay,
}: {
    creatorId: string
    sessionId: string
    hash: string
    onPlay: () => void
}): ReactElement => {
    const [recordingStream, setRecordingStream] = useState<{
        streamUrl: string
        broadcastIdentifier: string
    }>()

    const [failed, setFailed] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const streamControlsRef = useRef<StreamPlayerControls>()
    useEffect(() => {
        const getSetRecordingStream = async () => {
            setLoading(true)
            try {
                const { broadcastIdentifier } = await getSessionInformationWithSecret(sessionId, creatorId, hash)
                const stream = await awaitStream(`${streamBase}/${broadcastIdentifier}/${sessionId}.m3u8`, 10)
                setRecordingStream({
                    streamUrl: stream,
                    broadcastIdentifier,
                })
            } catch (e) {
                console.error(e)
                setFailed(true)
            } finally {
                setLoading(false)
            }
        }
        getSetRecordingStream()
    }, [sessionId])

    return (
        <>
            {!loading && failed && <div className="failureBox">couldn't get session audio</div>}
            {loading && (
                <div data-testid="loading" className="loadingBox">
                    <div className="spinner" />
                </div>
            )}
            {!loading && !failed && recordingStream && streamControlsRef && (
                <StreamPlayer
                    ref={streamControlsRef as MutableRefObject<StreamPlayerControls>}
                    streamUrl={recordingStream.streamUrl}
                    startPosition={0}
                    broadcastIdentifier={recordingStream.broadcastIdentifier}
                    errorReportContext="session log recording player"
                    onPlay={onPlay}
                />
            )}
        </>
    )
}

export default IntegrationAudio
