export enum FormElementType {
    STAR = "star-rating",
    HEADER = "header",
    DESCRIPTION = "description",
    SLIDER = "slider",
    FREE_TEXT = "free-text",
    SESSION_AUDIO = "session-audio",
}

export type FormResponseElement = {
    id: string
    text: string
    type: FormElementType
    min?: number
    max?: number
    value?: string | number
}

export enum FormTemplateType {
    POST_INTEGRATION = "postSessionIntegration",
}

export type FormResponse = {
    id?: string
    templateReferences: string[]
    sessionId?: string
    creatorId?: string
    dateCreated?: string
    responderEmail?: string
    type: FormTemplateType
    anonymous?: boolean
    elements: FormResponseElement[]
}

export type IntegrationFormResponse = {
    id: string
    templateReferences: string[]
    sessionId: string
    creatorId: string
    dateCreated: string
    responderEmail?: string
    type: FormTemplateType.POST_INTEGRATION
    anonymous?: boolean
    elements: FormResponseElement[]
}
