import Hls from "hls.js"
import { pick } from "lodash"

export function getHlsEventData(event: string, data: any) {
    switch (event) {
        case Hls.Events.MEDIA_ATTACHING:
        case Hls.Events.MEDIA_ATTACHED:
        case Hls.Events.MEDIA_DETACHING:
        case Hls.Events.MEDIA_DETACHED:
        case Hls.Events.BUFFER_RESET:
        case Hls.Events.BUFFER_APPENDING:
        case Hls.Events.BUFFER_EOS:
        case Hls.Events.AUDIO_TRACKS_UPDATED:
        case Hls.Events.FRAG_LOADING:
        case Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED:
        case Hls.Events.FRAG_PARSING_INIT_SEGMENT:
        case Hls.Events.FRAG_PARSING_USERDATA:
        case Hls.Events.FRAG_PARSING_METADATA:
        case Hls.Events.FRAG_PARSED:
        case Hls.Events.FRAG_CHANGED:
        case Hls.Events.DESTROYING:
            return { event }
        case Hls.Events.BUFFER_CODECS:
        case Hls.Events.BUFFER_CREATED:
            return { event, ...pick(data, "tracks") }
        case Hls.Events.BUFFER_APPENDED:
            return { event, ...pick(data, "pending", "timeRanges") }
        case Hls.Events.BUFFER_FLUSHING:
        case Hls.Events.BUFFER_FLUSHED:
            return { event, ...pick(data, "startOffset", "endOffset") }
        case Hls.Events.MANIFEST_LOADING:
            return { event, ...pick(data, "url") }
        case Hls.Events.MANIFEST_LOADED:
            return { event, ...pick(data, "url", "stats") }
        case Hls.Events.MANIFEST_PARSED:
            return { event, ...pick(data, "firstLevel") }
        case Hls.Events.LEVEL_SWITCHING:
            return { event, ...data.level }
        case Hls.Events.LEVEL_SWITCHED:
            return { event, ...pick(data, "level") }
        case Hls.Events.LEVEL_LOADING:
            return { event, ...pick(data, "level", "url") }
        case Hls.Events.LEVEL_LOADED:
        case Hls.Events.LEVEL_UPDATED:
        case Hls.Events.LEVEL_PTS_UPDATED:
        case Hls.Events.AUDIO_TRACK_LOADED:
            return {
                event,
                ...pick(data, "level", "stats"),
                details: pick(
                    data.details,
                    "version",
                    "type",
                    "startSN",
                    "endSN",
                    "totalDuration",
                    "targetDuration",
                    "live"
                ),
            }
        case Hls.Events.AUDIO_TRACK_SWITCHING:
        case Hls.Events.AUDIO_TRACK_SWITCHED:
            return { event, id: data.id }
        case Hls.Events.AUDIO_TRACK_LOADING:
            return { event, ...pick(data, "url", "id") }
        case Hls.Events.INIT_PTS_FOUND:
            return { event, ...pick(data, "d", "initPTS") }
        case Hls.Events.FRAG_LOAD_PROGRESS:
        case Hls.Events.FRAG_LOADED:
        case Hls.Events.FRAG_DECRYPTED:
        case Hls.Events.FRAG_BUFFERED:
            return { event, ...pick(data, "stats") }
        case Hls.Events.FRAG_PARSING_DATA:
            return { event, ...pick(data, "id", "startPTS", "endPTS", "startDTS", "endDTS", "type", "nb") }
        case Hls.Events.FPS_DROP:
            return { event, ...pick(data, "currentDropped", "currentDecoded", "totalDroppedFrames") }
        case Hls.Events.FPS_DROP_LEVEL_CAPPING:
            return { event, ...pick(data, "level", "droppedLevel") }
    }
}

export function getHlsErrorContext(data: Hls.errorData) {
    switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
            return pick(data, "type", "details", "url", "reason")
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.AUDIO_TRACK_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.AUDIO_TRACK_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.KEY_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.KEY_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
            return pick(data, "type", "details", "reason")
        case Hls.ErrorDetails.FRAG_PARSING_ERROR:
            return pick(data, "type", "details", "reason")
        case Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR:
            return pick(data, "type", "details", "err", "mimeType")
        case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            return pick(data, "type", "details", "buffer")
        case Hls.ErrorDetails.BUFFER_FULL_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE:
            return pick(data, "type", "details", "hole")
        case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.REMUX_ALLOC_ERROR:
            return pick(data, "type", "details", "bytes", "reason")
        case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
            return pick(data, "type", "details", "level", "reason")
        case Hls.ErrorDetails.INTERNAL_EXCEPTION:
            return pick(data, "type", "details", "err")
        default:
            return data
    }
}

export async function awaitStream(streamUrl: string, maxTries = 50): Promise<string> {
    let triesLeft = maxTries,
        networkError
    while (triesLeft-- > 0) {
        try {
            const res = await fetch(streamUrl, { method: "HEAD" })
            if (res.status === 200) {
                return streamUrl
            }
        } catch (e) {
            networkError = e
        }
        await new Promise((res) => setTimeout(res, 2000))
    }
    throw networkError || new Error(`Stream ${streamUrl} does not seem to exist`)
}
