import React from "react"
import H5AudioPlayer from "react-h5-audio-player"

import "react-h5-audio-player/src/styles.scss"

import "./AudioPlayer.scss"

const AudioPlayer = React.forwardRef<H5AudioPlayer, any>((props, ref) => {
    return (
        <div className="audioFilePlayer" data-testid="player">
            <H5AudioPlayer ref={ref} {...props} />
        </div>
    )
})

export default AudioPlayer
