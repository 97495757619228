import React, { useEffect, useState, useReducer } from "react"
import _ from "lodash"
import { FormResponse, FormResponseElement, FormElementType, IntegrationFormResponse } from "../types/formTypes"
import { getFormResponse, putFormResponse } from "../util/formApi"
import LabeledStarRating from "../components/client-integration/LabeledStarRating"
import LabeledSlider from "../components/client-integration/LabeledSlider"
import IntegrationAudio from "../components/client-integration/IntegrationAudio"
import EmptyLayout from "../components/EmptyLayout"
import LabeledTextInput from "../components/client-integration/LabeledTextInput"
import { useMixpanel } from "../../plugins/gatsby-plugin-mixpanel"
import Helmet from "react-helmet"

import "./clientIntegration.scss"

export const getFormResponseCompletionRate = (elements: FormResponseElement[]): number => {
    const questionTypes = [FormElementType.STAR, FormElementType.SLIDER, FormElementType.FREE_TEXT]
    const formQuestions = elements.filter((element) => questionTypes.includes(element.type))
    const noCompletedQuestions = formQuestions.reduce(
        (previous, current) => previous + (_.isUndefined(current.value) ? 0 : 1),
        0
    )
    return noCompletedQuestions / formQuestions.length
}

function FormComponent({
    formElement,
    onChangeValue,
    form,
    hash,
}: {
    formElement: FormResponseElement
    hash: string
    onChangeValue: (id: number, value: string | number) => void
    form: IntegrationFormResponse
}) {
    const mixpanel = useMixpanel()
    switch (formElement.type) {
        case FormElementType.STAR:
            return <LabeledStarRating formElement={formElement} onChangeValue={onChangeValue} />
        case FormElementType.SLIDER:
            return <LabeledSlider formElement={formElement} onChangeValue={onChangeValue} />
        case FormElementType.HEADER:
            return <h3 data-testid={`header-${formElement.id}`}>{formElement.text}</h3>
        case FormElementType.DESCRIPTION:
            return (
                <p className="description" data-testid={`description-${formElement.id}`}>
                    {formElement.text}
                </p>
            )
        case FormElementType.FREE_TEXT:
            return <LabeledTextInput formElement={formElement} onChangeValue={onChangeValue} />
        case FormElementType.SESSION_AUDIO:
            return (
                <div data-testid={`sessionAudio-${formElement.id}`} className="audio">
                    <label htmlFor="integrationAudio" className="question">
                        {formElement.text}
                    </label>
                    <IntegrationAudio
                        onPlay={() => {
                            mixpanel.track("Play session audio", { session_id: form.sessionId, form_id: form.id })
                        }}
                        hash={hash}
                        creatorId={form.creatorId}
                        sessionId={form.sessionId}
                    ></IntegrationAudio>
                </div>
            )
        default:
            return null
    }
}

const formReducer = (state: any, action: any) => {
    switch (action.type) {
        case "changeElementValue":
            const newForm = { ...state }
            const key = _.findIndex(newForm.elements, (o: any) => o.id === action.id)
            _.set(newForm, `elements[${key}].value`, action.value)
            return newForm
    }
}

const getSessionIdFromURL = (): string | null | undefined => {
    if (typeof window !== "undefined") {
        const { search } = window.location
        const urlParams = new URLSearchParams(search)
        return urlParams.get("sessionId")
    }
}

const getHashFromURL = (): string | null | undefined => {
    if (typeof window !== "undefined") {
        const { search } = window.location
        const urlParams = new URLSearchParams(search)
        return urlParams.get("hash")
    }
}

// TODO: this is a nasty hack used because we don't want to rewrite dozens of components just before the release. A much better solution would be to:
// - drop global styles completely
// - split this thing into components
// - use a material-ui independent styling solution
//
// Problem:
// - namespace pollution (.content class is definedin 5+ places)
// - styles load in a different order between local and dev (!)
// - it's almost impossible to fix without rewriting shared components and causing regressions as our code seems to rely on those
// - material ui styling API is unstable and their solution is a) bespoke b) 4x times slower than the industry standard alternatives
const bodyClassOverride = { class: "page__clientIntegration" }
const StyleFixHack = () => <Helmet bodyAttributes={bodyClassOverride} key="helmet" />

const ClientIntegrationQueryReader = () => {
    const [sessionId, setSessionId] = useState(getSessionIdFromURL)
    const [hash, setHash] = useState(getHashFromURL)
    const [jungState, setjungState] = useState("blur")
    if (sessionId && hash) {
        return (
            <EmptyLayout jungState={jungState}>
                <StyleFixHack />
                <ClientIntegrationFormResponseApiHandler sessionId={sessionId} hash={hash} />
            </EmptyLayout>
        )
    } else {
        //failure state 1
        return (
            <EmptyLayout jungState={jungState}>
                <div>
                    <h3>Form Not Found</h3>
                    <p>please check you have a valid link</p>
                </div>
            </EmptyLayout>
        )
    }
}

export const ClientIntegrationFormResponseApiHandler = ({ sessionId, hash }: { sessionId: string; hash: string }) => {
    const mixpanel = useMixpanel()
    const [formResponse, setFormResponse] = useState<IntegrationFormResponse | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const [sending, setSending] = useState<boolean>(false)
    const [sendFailed, setSendFailed] = useState<boolean>(false)
    const [fetchFailed, setFetchFailed] = useState<boolean>(false)
    const [displaySaveSuccess, setDisplaySaveSuccess] = useState<boolean>(false)

    useEffect(() => {
        const getSetFormResponse = async () => {
            try {
                const formResponse = await getFormResponse(sessionId, hash)
                setFormResponse(formResponse)
                setFetchFailed(true)
                formResponse &&
                    mixpanel.track("Loaded client integration page", {
                        form_id: `${formResponse.id}`,
                        session_id: `${formResponse.sessionId}`,
                    })
            } catch (e) {
                setFetchFailed(true)
            } finally {
                setLoading(false)
            }
        }
        getSetFormResponse()
    }, [sessionId])

    const onPutFormResponse = async (id: string, state: FormResponse): Promise<void> => {
        setSending(true)
        setSendFailed(false)
        try {
            await putFormResponse(id, hash, state)
            if (formResponse) {
                const completionRate = getFormResponseCompletionRate(formResponse.elements)
                mixpanel.track("Saved client integration page", {
                    form_id: `${formResponse.id}`,
                    session_id: `${formResponse.sessionId}`,
                    completion_rate: `${completionRate}`,
                })
            }
            setSending(false)
            setDisplaySaveSuccess(true)
            setSendFailed(false)
            setTimeout(() => {
                setDisplaySaveSuccess(false)
            }, 3000)
        } catch (e) {
            console.log({ e })

            setSending(false)
            setSendFailed(true)
            //setTimeout(() => {setSendFailed(false)}, 10000)
            return
        }
    }

    if (formResponse) {
        return (
            <ClientIntegrationPage
                integrationForm={formResponse}
                onSubmitFormResponse={onPutFormResponse}
                sending={sending}
                sendFailed={sendFailed}
                displaySaveSuccess={displaySaveSuccess}
                hash={hash}
            />
        )
    } else {
        //Failure / loading state
        if (loading) {
            return <div data-testid="spinner" className={"bigSpinner"}></div>
        } else {
            return (
                <div>
                    <h4>We Could Not Fetch Your Data</h4>
                    <p>Please check you have a valid link</p>
                </div>
            )
        }
    }
}

export const ClientIntegrationPage = ({
    integrationForm,
    onSubmitFormResponse,
    sending,
    sendFailed,
    displaySaveSuccess,
    hash,
}: {
    integrationForm: IntegrationFormResponse
    onSubmitFormResponse: (id: string, state: FormResponse) => Promise<void | Response>
    sending: boolean
    sendFailed: boolean
    displaySaveSuccess: boolean
    hash: string
}) => {
    const [state, dispatch] = useReducer(formReducer, integrationForm)

    const onChangeValue = (id: number, value: string | number) => {
        dispatch({ type: "changeElementValue", id, value })
    }

    return (
        <>
            <div className="wrapper">
                <div className="clientIntegrationInputs" data-testid="formInputsContainer">
                    {integrationForm.elements.map((formElement) => (
                        <div key={formElement.id} className="clientIntegrationFormInput">
                            <FormComponent
                                form={integrationForm}
                                hash={hash}
                                formElement={formElement}
                                onChangeValue={onChangeValue}
                            />
                        </div>
                    ))}
                    <div className="submitButtonContainer">
                        {!sending && (
                            <button
                                className="submitButton"
                                onClick={(e) => {
                                    e.preventDefault()
                                    onSubmitFormResponse(state.id, state)
                                }}
                            >
                                Save
                            </button>
                        )}
                        {sending && <div data-testid="submitSpinner" className="spinner" />}
                    </div>
                </div>
            </div>
            {sendFailed && (
                <div className="failureBanner"> Sorry, we were unable to save your response, please try again.</div>
            )}
            {displaySaveSuccess && <div className="successBanner"> Save successful </div>}
        </>
    )
}

export default ClientIntegrationQueryReader
