import React, { useState } from "react"
import "./LabeledStarRating.scss"
import Ratings from "react-ratings-declarative"
const StarRating = ({ rating, onChangeRating }) => (
    <Ratings
        rating={rating}
        widgetHoverColors="yellow"
        widgetRatedColors="yellow"
        widgetDimensions="25px"
        widgetSpacings="0px"
        changeRating={onChangeRating}
    >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
    </Ratings>
)

const LabeledStarRating = ({ formElement, onChangeValue }) => {
    const [rating, setRating] = useState({ rating: formElement.id, value: formElement.value ? formElement.value : 0 })
    const onChangeRating = (value: number) => {
        const newRating = { ...rating }
        newRating.value = value
        setRating(newRating)
        onChangeValue(formElement.id, value)
    }
    return (
        <div className="input--rating" data-testid={`starRating-${formElement.id}`}>
            <p className="input--ratingLabel">{formElement.text}</p>
            <StarRating rating={rating.value} onChangeRating={(r: any) => onChangeRating(r)} />
        </div>
    )
}

export default LabeledStarRating
