import { FormResponse, IntegrationFormResponse } from "../types/formTypes"
import { fetchWithTimeout } from "./netUtil"
import config from "../config"

export const getFormResponse = async (
    sessionId: string,
    hash: string
): Promise<IntegrationFormResponse | undefined> => {
    try {
        const res = await fetchWithTimeout(
            `${config.GATSBY_CLOUD_FUNCTIONS_BASE_URL}/formResponses?sessionId=${sessionId}&hash=${hash}`,
            { method: "GET" },
            9000
        )
        const { formResponses } = await res.json()
        return formResponses[0]
    } catch (e) {
        console.error(e)
    }
}

export const putFormResponse = async (formId: string, hash: string, formResponse: FormResponse): Promise<void> => {
    console.log("Put called", formId, "hash", hash)
    try {
        await fetchWithTimeout(
            `${config.GATSBY_CLOUD_FUNCTIONS_BASE_URL}/formResponses/${formId}?hash=${hash}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formResponse),
            },
            10000
        )
    } catch (e) {
        console.error(e)
        throw new Error(e)
    }
}
