import React, { useState, useEffect, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { useSpring, animated, config } from "react-spring"

import "./reset.scss"
import "./EmptyLayout.scss"

import { JungContext, useAudioInit } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"

/* Light Mode 1 */
const colorLM1Elements = "255, 255, 255"
const colorLM1Text = "255, 255, 255"

const EmptyLayout = ({ children, jungState = "blur" }) => {
    let jungContext = useContext(JungContext)
    let audioInit = useAudioInit()
    let [mounted, setMounted] = useState(false)

    let { mount } = useTransitionState()
    let style = useSpring({
        opacity: mounted ? 1 : 0,
        config: { ...config.slow, duration: 850 },
    })
    useEffect(() => {
        setMounted(mount)
    }, [mount])
    useEffect(() => jungContext.setState(jungState), [jungState])

    return (
        <div
            className="app"
            onClick={audioInit}
            style={{
                "--colorElements": colorLM1Elements,
                "--colorText": colorLM1Text,
            }}
        >
            <main className="appMain">
                <animated.div className="content" style={style}>
                    {children}
                </animated.div>
            </main>
        </div>
    )
}

EmptyLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default EmptyLayout
