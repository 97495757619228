import React, { useRef, useEffect, MutableRefObject, RefObject } from "react"
import Hls from "hls.js"
import * as Sentry from "@sentry/browser"
import { getHlsEventData, getHlsErrorContext } from "../../util/streamUtils"

import _ from "lodash"
import H5AudioPlayer from "react-h5-audio-player"
import AudioPlayer from "../AudioPlayer"

interface StreamPlayerProps {
    streamUrl: string
    startPosition: number
    broadcastIdentifier: string
    errorReportContext: string
    onPlay?: () => void
}
export interface StreamPlayerControls {
    setTime: (time: number) => void
}
export const StreamPlayer = React.forwardRef<StreamPlayerControls, StreamPlayerProps>(
    ({ streamUrl, startPosition, broadcastIdentifier, errorReportContext, onPlay }, ref) => {
        const elRef = useRef<H5AudioPlayer>()

        useEffect(() => {
            if (elRef?.current?.audio.current) {
                elRef.current.audio.current.crossOrigin = "anonymous"

                ref &&
                    ((ref as MutableRefObject<StreamPlayerControls>).current = {
                        setTime: (time: number) => {
                            elRef.current?.audio.current && (elRef.current.audio.current.currentTime = time)
                            elRef.current?.audio.current && elRef.current?.audio.current.play()
                        },
                    })

                if (Hls.isSupported()) {
                    const hls = new Hls({
                        liveSyncDurationCount: 3,
                        initialLiveManifestSize: 3,
                    })
                    hls.loadSource(streamUrl)
                    const retryManifestLoad = (event: "hlsError", data: Hls.errorData) => {
                        if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
                            console.log("HLS Manifest not available, retrying in 1s")
                            setTimeout(() => hls.loadSource(streamUrl), 1000)
                        }
                    }
                    hls.on(Hls.Events.ERROR, retryManifestLoad)
                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        console.log("HLS Manifest loaded")
                        hls.off(Hls.Events.ERROR, retryManifestLoad)
                        Object.keys(Hls.Events).forEach((e) => {
                            hls.on((Hls.Events as any)[e], (event, data) => {
                                const evtData = getHlsEventData(event, data)
                                if (evtData) {
                                    Sentry.addBreadcrumb({
                                        category: "streaming",
                                        data: evtData,
                                    })
                                }
                            })
                        })
                        hls.on(Hls.Events.ERROR, (event, data) => {
                            if (data.fatal) {
                                switch (data.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        console.error("fatal network error encountered, try to recover", data)
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(data))
                                            Sentry.captureMessage(`Streaming network error in ${errorReportContext}`)
                                        })
                                        hls.startLoad(startPosition)
                                        break
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        console.error("fatal media error encountered, try to recover", data)
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(data))
                                            Sentry.captureMessage(`Streaming media error in ${errorReportContext}`)
                                        })
                                        hls.recoverMediaError()
                                        break
                                    default:
                                        console.error("fatal streaming error", data)
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(data))
                                            Sentry.captureMessage(`Streaming error in ${errorReportContext}`)
                                        })
                                        hls.destroy()
                                        break
                                }
                            } else if (
                                data.type === Hls.ErrorTypes.MEDIA_ERROR &&
                                data.details === "bufferStalledError"
                            ) {
                                Sentry.withScope((scope) => {
                                    scope.setExtra("broadcastIdentifier", broadcastIdentifier)
                                    scope.setExtra("context", getHlsErrorContext(data))
                                    Sentry.captureMessage(`Streaming network glitch in ${errorReportContext}`)
                                })
                            }
                        })
                    })
                    hls.attachMedia(elRef.current.audio.current as any)
                    return () => {
                        hls.destroy()
                    }
                } else if (elRef.current.audio.current.canPlayType("application/vnd.apple.mpegurl")) {
                    elRef.current.audio.current.src = streamUrl
                }
            }
        }, [streamUrl, startPosition, broadcastIdentifier, errorReportContext])

        return (
            <AudioPlayer
                ref={elRef as RefObject<H5AudioPlayer>}
                layout="horizontal-reverse"
                showJumpControls={false}
                customVolumeControls={[]}
                customAdditionalControls={[]}
                onPlay={onPlay}
            />
        )
    }
)
